<template>
  <b-modal
    id="consultation-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="tissGuide?.id">
          Atualizar guia de consulta - Nº {{ form.provider_guide }}
        </span>
        <span v-else>
          Nova guia de consulta - Nº {{ form.provider_guide }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <GuideDataModule
        :form="form"
        :fields="{ tiss_invoice_id: true, request_id: true, situation: true, onlending: true }"
        :validated="validated"
        :readonly="readonly"
        :tissBatchId="tissGuide?.tiss_batch_id"
        :healthPlanId="healthPlan ? healthPlan.id : null"
        :tissGuide="tissGuide"
      />

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="ans">Registro ANS</label>
            <b-form-input id="ans" maxlength="6" v-model="form.ans" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="provider_guide">Nº da guia do prestador</label>
            <ProviderGuideInput
              :providerGuide="form.provider_guide"
              :validateProviderGuide="validateProviderGuide"
              type="consultation"
              :validated="validated"
              :guideNumberExistsInBatch="guideNumberExistsInBatch"
              :state="
                validated && !form.provider_guide ? 'Campo obrigatório' : null
              "
              @update:provider-guide="form.provider_guide = $event"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="operator_guide">Nº da guia operadora</label>
            <b-form-input
              id="operator_guide"
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.operator_guide"
              :readonly="readonly"
              :state="validProp('operator_guide')"
            />
            <b-form-invalid-feedback :state="validProp('operator_guide')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do beneficiário (Paciente)</p>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label for="wallet_number"> Nº da carteira do beneficiário </label>
            <b-form-input
              id="wallet_number"
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.wallet_number"
              :readonly="readonly"
              :state="validProp('wallet_number')"
            />
            <b-form-invalid-feedback :state="validProp('wallet_number')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label for="wallet_expiration_date"> Validade da carteira </label>
            <date-picker
              id="wallet_expiration_date"
              name="wallet_expiration_date"
              placeholder="Selecionar"
              v-model="form.wallet_expiration_date"
              format="DD/MM/YYYY"
              :clearable="false"
              :lang="langDatePicker"
              class="full"
              :disabled="readonly"
              :state="validProp('wallet_expiration_date')"
            />
            <b-form-invalid-feedback
              :state="validProp('wallet_expiration_date')"
            >
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <label for="newborn_care">Recém-nascido</label>
            <div class="mt-3">
              <label class="checkbox" align="center">
                <input
                  id="newborn_care"
                  type="checkbox"
                  v-model="form.newborn_care"
                  :disabled="readonly || (patient && patient.birthday)"
                />
                <span
                  class="checkmark"
                  :class="{
                    'check-disable': readonly || (patient && patient.birthday)
                  }"
                >
                  <Check />
                </span>
              </label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6" class="mt-3">
          <b-form-group>
            <label for="beneficiary_name">Nome do beneficiário</label>
            <b-form-input
              id="beneficiary_name"
              maxlength="70"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.beneficiary_name"
              :readonly="true"
              :state="validProp('beneficiary_name')"
            />
            <b-form-invalid-feedback :state="validProp('beneficiary_name')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="6" class="mt-3">
          <b-form-group>
            <label for="national_health_card"> Cartão Nacional de Saúde </label>
            <b-form-input
              id="national_health_card"
              maxlength="15"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.national_health_card"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do contratado</p>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="contracted_code_type">Tipo do código contratado</label>
            <multiselect
              v-model="form.contracted_code_type"
              placeholder="Selecionar"
              :options="contractedCodeTypes"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>

        <ContractedField
          :cols="4"
          label="Contratado executante"
          id="contractor_name"
          :readonly="readonly"
          :vModel="form.contractor_name"
          :validated="validated"
          :contracteds="contracteds"
          @select-contracted="selectContracted"
        />

        <b-col cols="3">
          <b-form-group>
            <label for="operator_code">
              Código na Operadora
            </label>
            <b-form-input
              id="operator_code"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.operator_code"
              :readonly="readonly"
              v-mask="mask"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <label for="contractor_cnes">CNES</label>
            <b-form-input
              id="contractor_cnes"
              maxlength="7"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.contractor_cnes"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do profissional executante</p>
        </b-col>
        <b-col cols="7" class="mt-3">
          <b-form-group>
            <label for="professional_selected">Nome do profissional</label>
            <ProfessionalFieldModule
              :settingsId="form.invoicing_tiss_setting_id"
              :form="form"
              :multiselect="true"
              :readonly="
                readonly || (onlendingStatus && onlendingStatus !== 'Em aberto')
              "
              :fillProfessionalData="fillProfessionalData"
              @change-professionals="professionals = $event"
            />
            <b-form-invalid-feedback :state="validProp('professional_id')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="2" class="mt-3">
          <b-form-group>
            <label for="professional_council">Conselho</label>
            <multiselect
              v-model="form.professional_council"
              id="professional_council"
              :options="professionalCouncils"
              label="label"
              track-by="value"
              :allow-empty="false"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="true"
              placeholder="Selecionar"
              class="with-border"
              disabled
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-80">
                  {{ option.label }}
                </span>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="mt-3">
          <b-form-group>
            <label for="professional_council_registration"> Nº conselho </label>
            <b-form-input
              id="professional_council_registration"
              maxlength="15"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.professional_council_registration"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col cols="2" class="mt-3">
          <b-form-group>
            <label for="uf">UF</label>
            <multiselect
              id="uf"
              v-model="form.uf"
              placeholder="Selecionar"
              :options="statesUf"
              track-by="value"
              label="label"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              disabled
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="mt-3">
          <b-form-group>
            <label for="cbo_code">CBO</label>
            <multiselect
              id="cbo_code"
              v-model="form.cbo_code"
              placeholder="Selecionar"
              :options="cbos"
              track-by="value"
              label="label"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              disabled
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-80">
                  {{ option.value }}
                </span>
              </template>
              <template slot="noOptions">Nenhuma opção</template>
              <template slot="noResult">Nenhum resultado</template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="7" class="mt-3">
          <b-form-group>
            <label for="cbo_description">Descrição CBO</label>
            <b-input
              id="cbo_description"
              maxlength="100"
              placeholder="Descrever"
              autocomplete="off"
              :value="form.cbo_code?.label?.replace(/\d+/g, '')"
              readonly
            >
              {{ form.cbo_description }}
            </b-input>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <label for="accident_indication">Indicação de acidente</label>
            <multiselect
              id="accident_indication"
              v-model="form.accident_indication"
              placeholder="Selecionar"
              :options="accidentIndications"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
              :state="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="validProp('accident_indication')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-show="xml_version && xml_version[0] === '4'">
          <b-form-group>
            <label for="special_coverage"
              >Indicador de Cobertura Especial</label
            >
            <multiselect
              id="special_coverage"
              v-model="form.special_coverage"
              placeholder="Selecionar"
              :options="specialCoverages"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-show="xml_version && xml_version[0] === '4'">
          <b-form-group>
            <label for="attendance_regime">Regime de atendimento</label>
            <multiselect
              id="attendance_regime"
              v-model="form.attendance_regime"
              placeholder="Selecionar"
              :options="attendanceRegimes"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div 
              v-if="validated && xml_version && xml_version[0] === '4' && !form.attendance_regime"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-show="xml_version && xml_version[0] === '4'">
          <b-form-group>
            <label for="occupational_health">Saúde Ocupacional</label>
            <multiselect
              id="occupational_health"
              v-model="form.occupational_health"
              placeholder="Selecionar"
              :options="occupationalHealthOptions"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <label for="attendance_date">
              <Ellipsis>Data de realização</Ellipsis>
            </label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.attendance_date"
              name="attendance_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="attendance_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
              :state="validProp('attendance_date')"
            />
            <b-form-invalid-feedback :state="validProp('attendance_date')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="attedance_type">Tipo de consulta</label>
            <multiselect
              id="attedance_type"
              v-model="form.attedance_type"
              placeholder="Selecionar"
              :options="consultationTypes"
              track-by="value"
              label="label"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="validProp('attedance_type')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col :cols="xml_version && xml_version[0] === '4' ? 7 : 4">
          <b-form-group>
            <label for="table_name">Tabela</label>
            <b-form-input
              id="table_name"
              maxlength="2"
              autocomplete="off"
              :placeholder="parseInt(form?.table_name) === 22 
                ? 'TUSS - Procedimentos e eventos em saúde (medicina, odonto e demais áreas)' 
                : ( parseInt(form?.table_name) == 0 ?
                  'TUSS - Tabela própria das operadoras' :                        
                  form?.table_name)
              "
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col cols="9">
          <b-form-group>
            <label for="procedureId">Procedimento</label>
            <ItemHealthPlanSimpleSelector 
              :clinicHealthPlanId="clinicHealthPlanId"
              :planId="null"
              :allowedTypes="['PROCEDURE','APPOINTMENT']"
              v-model="form.item"
              @select="setItem"
            />

            <!-- <ProcedureFieldModule
              :tissGuideItem="form"
              :settingsId="form.invoicing_tiss_setting_id"
              :readonly="readonly"
              @changeProcedureValue="changeProcedureValue"
              @change-operational-cost-and-medical-fee="
                changeOperationalCostAndMedicalFee
              "
            /> -->
          </b-form-group>
        </b-col>

        <!-- <b-col cols="5">
          <b-form-group>
            <label for="procedure_description">Descrição procedimento</label>
            <b-form-input
              id="procedure_description"
              placeholder="Selecione o procedimento"
              autocomplete="off"
              :value="form.item.name"
              readonly
            />
          </b-form-group>
        </b-col> -->

        <b-col cols="3">
          <b-form-group>
            <label for="procedure_value">Valor do procedimento</label>
            <money
              id="procedure_value"
              v-model="form.procedure_value"
              class="form-control"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-3">
          <b-form-group>
            <label for="observation">Observação / Justificativa</label>
            <b-form-textarea
              id="observation"
              maxlength="500"
              placeholder="Descrever"
              rows="2"
              max-rows="5"
              autocomplete="off"
              v-model="form.observation"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="tissGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateTissGuide"
          :disabled="tissGuide.situation === 'Cancelada' || alreadySent"
          v-if="tissGuide && tissGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          :disabled="alreadySent"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import api from '@/modules/tiss/manageGuides/api'
import { mapState } from 'vuex'
// import { getProcedureValue } from '@/utils/invoicingTissHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import professionalFieldMixin from '@/mixins/professionalFieldMixin'
import { TissBatch } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'ConsultationGuideModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    GuideDataModule: () => import('./Modules/GuideDataModule'),
    // ProcedureFieldModule: () => import('./Modules/ProcedureFieldModule'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProviderGuideInput: () =>
      import('@/components/Tiss/Generic/ModalComponents/ProviderGuideInput'),
    ContractedField: () =>
      import('@/components/Tiss/Guides/Modules/ContractedField'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
  },
  props: {
    patient: [Object, null],
    healthPlan: Object || null,
    clinicHealthPlanId: String || null,
    invoicing_tiss_setting: Object,
    appointmentId: String,
    required_fields: Object,
    tissGuide: Object,
    guideNumber: Number,
    providerGuideReadonly: Boolean,
    validateProviderGuide: Function,
    guideNumberExistsInBatch: Boolean,
    guideNumberExists: Boolean,
    providerGuideUpdated: Number,
    contracteds: Array
  },
  mixins: [professionalFieldMixin('form', 'tissGuide')],
  data() {
    return {
      clinic: getCurrentClinic(),
      professionalCouncils: require('./../Data/professionalCouncils.json'),
      statesUf: require('./../Data/statesUf.json'),
      cbos: require('./../Data/cbos.json'),
      accidentIndications: require('./../Data/accidentIndications.json'),
      consultationTypes: require('./../Data/consultationTypes.json'),
      specialCoverages: require('./../Data/specialCoverages.json'),
      attendanceRegimes: require('./../Data/attendanceRegimes.json'),
      occupationalHealthOptions: require('./../Data/occupationalHealthOptions.json'),
      contractedCodeTypes: require('./../Data/contractedCodeTypes.json'),
      validated: false,
      alreadySent: false,
      form: {},
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      xml_version: null,
      onlendingStatus: null,
      healthPlanRequiredFields: {},
    }
  },
  computed: {
    ...mapState('manageGuides', ['requiredFields']),
    readonly() {
      return this.tissGguide && Boolean(
        this.tissGuide.id &&
          (['Finalizada'].includes(this.tissGuide.situation) ||
            (this.tissGuide.tiss_batch_id &&
              this.tissGuide.tiss_batch?.status !==
                TissBatch.BATCH_STATUS_TO_SENT))
      )
    },
    mask() {
      if (!this.form.contracted_code_type) return '##############'
      return (
        {
          'CNPJ do contratado': '##.###.###/####-##',
          'CPF do contratado': '###.###.###-##',
          default: '##############'
        }[this.form.contracted_code_type.value] || '##############'
      )
    },
  },
  methods: {
    async onShow() {
      await this.getHealthPlanRequiredFields()
      this.getPatientWallet()
      this.xml_version =
        this.invoicing_tiss_setting?.tiss_information?.XML_version
      if (!this.tissGuide?.id) {
        await this.setSelectsFields()
      }
      this.onlendingStatus =
        this.tissGuide?.items?.length &&
        this.tissGuide.items[0]?.professionals[0]?.onlending_status

    },
    getDefaultForm() {
      return {
        clinic_id: this.clinic.id,
        id: null,
        item: null,
        item_id: null,
        request_id: null,
        bank_account_id: this.invoicing_tiss_setting?.bank_account_id,

        situation: null,
        tiss_invoice_id: null,

        invoicing_tiss_setting_id: this.invoicing_tiss_setting?.id,
        appointment_id: this.appointmentId || null,
        appointment_item_id: null,

        ans: this.healthPlan?.ans_code,
        provider_guide: this.guideNumber,
        operator_guide: null,

        wallet_number: null,
        wallet_expiration_date: null,
        beneficiary_name: null,
        national_health_card: null,
        newborn_care: false,

        operator_code: this.invoicing_tiss_setting?.hired_executant_code,
        contractor_name: this.invoicing_tiss_setting?.hired_executant_name,
        contractor_cnes: this.invoicing_tiss_setting?.CNES,
        contracted_code_type: this.invoicing_tiss_setting?.provider_code,
        professional_selected: {},
        professional_id: null,
        onlending_professional: null,
        professional_council: null,
        professional_council_registration: null,
        uf: null,
        cbo_code: null,

        accident_indication: null,

        attendance_date: this.moment().toDate(),
        attedance_type: this.invoicing_tiss_setting?.consultation_type,
        attendance_regime: null,
        table_name: null,
        procedure_description: null,
        procedure_value: 0,
        observation: null,
        operational_cost: 0,
        medical_fee: 0
      }
    },
    async setSelectsFields() {
      if (this.tissGuide?.id) return 

      this.form.contracted_code_type = this.invoicing_tiss_setting?.provider_code
          ? this.contractedCodeTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting.provider_code
            )
          : null;

      this.form.beneficiary_name = this.patient?.name
      this.form.national_health_card = this.form.national_health_card ?? this.patient?.cns
      this.form.newborn_care = this.patient?.birthday
        ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
        : false

      this.form.accident_indication = this.invoicing_tiss_setting
        ?.accident_indication
        ? this.accidentIndications.find(
            option =>
              option.label === this.invoicing_tiss_setting.accident_indication
          )
        : null


      this.form.attedance_type = !this.tissGuide.id ? 
      (this.invoicing_tiss_setting?.consultation_type ? 
        this.consultationTypes.find(option => option.label === this.invoicing_tiss_setting.consultation_type)
        : null
      ):
      (this.consultationTypes.find(option => option.value === this.tissGuide.guide.attedance_type) ?? null)

      this.form.appointment_item_id = this.tissGuide?.appointment_item_id
    },
    clearForm() {
      this.form = this.getDefaultForm()
      this.getPatientWallet()
      this.validated = false
    },
    validProp(prop) {
      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields
      if (!this.validated || !requiredTissFields[prop]) return null
      return !!this.form[prop]
    },
    isValidForm() {
      this.validated = true

      if (this.guideNumberExists) {
        this.$toast.error(
          'Este Nº de guia já existe, resolva o conflito para continuar.'
        )
        return false
      }

      // Se houverem campos obrigatórios do convênio, utilizá-los. Se não, utilizar os setados para a clínica
      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields

      const consultationKeys = [
        'provider_guide',
        'wallet_number',
        'wallet_expiration_date',
        'beneficiary_name',
        'accident_indication',
        'attendance_date',
        'attedance_type',
        'operator_guide',
        'professional_id'
      ]

      if (this.xml_version[0] && this.xml_version[0] === '4' && !this.form.attendance_regime) {
        return false
      }
      if (
          (requiredTissFields.professional_id) &&
        !this.form.professional_selected?.value
      )
        return false

      return !Object.keys(requiredTissFields)
        .filter(key => consultationKeys.includes(key))
        .some(key => requiredTissFields[key] && !this.form[key])
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    getSelectedValue() {
      if (typeof this.form.tiss_invoice_id === 'object')
        this.form.tiss_invoice_id = this.form.tiss_invoice_id
          ? this.form.tiss_invoice_id?.value
          : null
      if (typeof this.form.professional_council === 'object')
        this.form.professional_council = this.form.professional_council?.value
      if (typeof this.form.uf === 'object') this.form.uf = this.form.uf?.value
      if (typeof this.form.cbo_code === 'object')
        this.form.cbo_code = this.form.cbo_code?.value

      this.form.item_id = this.form.item?.id
      this.form.contracted_code_type = this.form.contracted_code_type?.value ?? null
      this.form.accident_indication = this.form.accident_indication?.value ?? null
      this.form.attendance_regime = this.form.attendance_regime?.value ?? null
      this.form.occupational_health = this.form.occupational_health?.value ?? null
      this.form.special_coverage = this.form.special_coverage?.value ?? null

      if (typeof this.form.attedance_type === 'object')
        this.form.attedance_type = this.form.attedance_type?.value

      if (Object.keys(this.form.professional_selected).length)
        this.form.professional_id = this.form.professional_selected?.value
    },
    async createGuide() {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      this.alreadySent = true
      const isLoading = this.$loading.show()
      try {
        this.getSelectedValue()
        this.form.patient_id = this.patient.id
        this.form.clinic_id = this.clinic.id
        this.form.onlending_professional_id = this.form.onlending_professional?.id || null;
        const response = await this.api.createConsultationGuide(this.form)
        this.$emit('refresh-tiss-guides', {
          id: response.data.id,
          guide_id: this.tissGuide.id
        })
        this.$toast.success('Guia de consulta criada com sucesso!')
        this.closeModal()
      } catch (err) {
        console.log(err);
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
        this.alreadySent = false
      }
    },
    async updateTissGuide() {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      this.alreadySent = true
      const isLoading = this.$loading.show()
      try {
        this.getSelectedValue()
        this.form.clinic_id = this.clinic.id
        this.form.onlending_professional_id = this.form.onlending_professional?.id || null;
        const { data } = await this.api.updateConsultationGuide(
          this.tissGuide.guide.id,
          this.form
        )
        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: this.tissGuide.id
        })
        this.$toast.success('Guia de consulta editada com sucesso!')
        this.closeModal()
      } catch (err) {
        console.log(err);
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
        this.alreadySent = false
      }
    }, 
    closeModal() {
      this.clearForm()
      this.$emit('onHide')
      this.$bvModal.hide('consultation-guide-modal')
    },
    async getTissTableProcedureById(tissProcedureId) {
      if (!tissProcedureId) return
      try {
        const { data } = await this.api.findTissTableProcedureById(
          tissProcedureId
        )
        return data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async findTissTableProcedure(procedureId) {
      try {
        const { data } = await this.api.findTissTableProcedure(
          procedureId,
          this.invoicing_tiss_setting?.id ?? this.form.invoicing_tiss_setting_id
        )
        return data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setItem(item) {
      this.form.item = item
      this.form.item_id = item.id
      const medicalFee = item?.health_plan_props?.medical_fee ?? item.medical_fee
      const operationalCost = item?.health_plan_props?.operational_cost ?? item.operational_cost
      const m2Filme = item?.health_plan_props?.m2_filme ?? item.m2_filme
      const anestheticPort = item?.health_plan_props?.anesthetic_port ?? item.anesthetic_port
      
      this.form.operational_cost = operationalCost || 0
      this.form.medical_fee = medicalFee || 0
      this.form.procedure_description = item.name
      this.form.table_name = item?.health_plan_props?.table_number ?? item.table_number

      this.form.procedure_value = medicalFee + 
        operationalCost + 
        m2Filme + 
        anestheticPort

    },
    // changeOperationalCostAndMedicalFee(value) {
    //   this.form.operational_cost = value?.operational_cost || 0
    //   this.form.medical_fee = value?.medical_fee || 0
    // },
    getPatientWallet() {
      if (this.tissGuide?.id) return

      const personPlan = this.patient?.person_health_plans?.find(
        plan => plan?.health_plan_id === this.healthPlan?.id
      )
      this.form.wallet_number = personPlan?.registration
      this.form.wallet_expiration_date = this.moment(
        personPlan?.valid_until
      ).toDate()
    },
    selectContracted(value) {
      this.form.contractor_name = value?.label
      this.form.operator_code = value?.operator_code.replace(/\D/g, '')
      this.form.contractor_cnes = value?.cnes
      // this.form.contracted_code_type = value?.code_type
      this.form.contracted_code_type = this.contractedCodeTypes.find(
        option => option.value === value?.code_type
      )
    },
    // getTissGuideRequiredFields() {
    //   api.getTissGuideRequiredFields(this.clinic.id).then(({ data }) => {
    //     this.requiredFields = data
    //   })
    // },
    async getHealthPlanRequiredFields() {
      if(this.clinicHealthPlanId){
        try {
          this.api.getHealthPlanRequiredFields(this.clinicHealthPlanId).then(({ data }) => {
            if(data.length){
              const temp = [];
              data.forEach(item => {
                if(item.name === 'professional_name') item.name = 'professional_id'
                temp[item.name] = Boolean(item.pivot.active);
              });
              this.healthPlanRequiredFields = temp;
            }
          })
        } catch (error) {
          console.error('Erro ao buscar requiredFields: ' + error)
        }
      }
    }
  },
  watch: {
    tissGuide: {
      async handler(newValue) {
        if (
          newValue?.guide_type ===
          'App\\Models\\TissGuide\\TissGuideConsultation'
        ) {
          this.form = {
            ...this.form,
            ...newValue.guide
          }
          this.form.provider_guide = newValue.provider_guide
          this.form.tiss_invoice_id = newValue.tiss_invoice_id

          this.form.invoicing_tiss_setting_id =
            newValue.invoicing_tiss_setting_id
          this.form.clinic_id = newValue.clinic_id
          this.form.tiss_guide_id = newValue.id
          this.form.situation = newValue.situation
          this.form.wallet_expiration_date = this.moment(
            this.form.wallet_expiration_date
          ).toDate()
          this.form.attendance_date = this.moment(
            this.form.attendance_date
          ).toDate()
          this.form.professional_council = this.professionalCouncils.find(
            option => option.value === newValue.guide.professional_council
          )
          this.form.attedance_type = this.consultationTypes.find(
            option => option.value === newValue.guide.attedance_type
          )
          this.form.uf = this.statesUf.find(
            option => option.value === newValue.guide.uf
          )
          this.form.cbo_code = this.cbos.find(
            option => option.value === newValue.guide.cbo_code
          )
          this.form.accident_indication = this.accidentIndications.find(
            option => option.value === newValue.guide.accident_indication
          )
          this.form.attendance_regime = this.attendanceRegimes.find(
            option => option.value === newValue.guide.attendance_regime
          )

          this.form.occupational_health = this.occupationalHealthOptions.find(
            option => option.value === newValue.guide.occupational_health
          )

          this.form.special_coverage = this.specialCoverages.find(
            option => option.value === newValue.guide.special_coverage
          )

          this.form.contracted_code_type = this.contractedCodeTypes.find(
            option => option.value === newValue.guide.contracted_code_type
          )

          this.form.item = newValue?.items[0]?.item
          this.form.item_id = newValue?.items[0]?.item.id || null
          this.form.medical_fee = newValue?.value || this.form.item?.health_plan_props?.medical_fee || 0
        } else if (newValue?.item_id) {
          this.form = this.getDefaultForm()
          this.form.appointment_id = newValue.appointment_id
          this.form.invoicing_tiss_setting_id = newValue.invoicing_tiss_setting_id
          this.$nextTick(() => this.setItem(newValue.item))
          await this.setSelectsFields()
          
        } else {
          this.clearForm()
        }
      },
      deep: true,
      immediate: true
    },
    guideNumber() {
      if (!this.tissGuide?.id) {
        this.form = this.getDefaultForm()
        this.getPatientWallet()
      }
    },
    'form.provider_guide': {
      handler(newValue) {
        if (newValue) {
          const form = {
            id: this.tissGuide.id,
            provider_guide: newValue,
            patient: {
              name: this.form.beneficiary_name
            }
          }
          this.$emit('update-form', {
            form: form,
            providerGuide: this.providerGuideUpdated ?? this.guideNumber
          })
        }
      },
      deep: true,
      immediate: true
    },
    providerGuideUpdated: {
      handler(newValue) {
        if (newValue) {
          this.form.provider_guide = newValue
        }
      },
      immediate: true
    },
    patient: {
      handler(value) {
        if (value) {
          this.form.beneficiary_name = this.form.beneficiary_name || value.name
          this.form.national_health_card =
            this.form.national_health_card ?? this.patient?.cns
          this.form.newborn_care =
            this.form.newborn_care || this.patient?.birthday
              ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
              : false
          this.getPatientWallet()
        }
      },
      deep: true,
      immediate: true
    },
    invoicing_tiss_setting: {
      async handler(value) {
        if (!value?.id) return
        this.xml_version = value?.tiss_information?.XML_version
        await this.setSelectsFields()
      },
      deep: true

    }
  }
}
</script>

<style lang="scss">
#consultation-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;
      }
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}
</style>
